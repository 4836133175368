<template>
  <l-default>
    <OTitle>Refund Policy</OTitle>
    <CLegal>
      <div id="refund"></div>
    </CLegal>
  </l-default>
</template>

<script>
import LDefault from '../../layouts/LDefault'
import OTitle from '../../objects/OTitle'
import CLegal from '../../components/CLegal'

export default {
  name: 'PageRefund',
  components: { CLegal, OTitle, LDefault },
  metaInfo: {
    title: 'Spoof Call And Caller ID Faker | Refund',
    meta: [
      {
        name: 'description',
        content: 'Spoof Call And Caller ID Faker - Refund'
      },
      {
        name: 'keywords',
        content: 'myphonerobot refund'
      }
    ]
  },
  mounted () {
    fetch('/static/refund.html')
      .then(response => response.text())
      .then(template => {
        document.getElementById('refund').innerHTML = template
      })
  }
}
</script>
